import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import useApi from "../../../../../../shared/hooks/useApi";
import { BodyOf } from "../../../../../../core/api";
import { formatErrorResponse } from "../../../../../../shared/utils/format-response-error";
import { VisitAssignmentId } from "../../../../../../shared/schema/schema";
import useControlledForm from "../../../../../../shared/hooks/useControlledForm";
import NewVisitAssignmentModalBody from "./NewVisitAssignmentModalBody";
import { zVisitAssignment } from "./visit-assignment-form";

type Props = {
  isOpen: boolean;
  onAdd: (id: VisitAssignmentId) => void;
  onClose: () => void;
};

export default function NewVisitAssignmentModal(props: Props) {
  const { api } = useApi();
  const toast = useToast();

  const onClose = () => {
    props.onClose();
    form.reset();
  };

  const form = useControlledForm({
    schema: zVisitAssignment,
    onSuccess: (data) => {
      addVisitAssignment.mutate({
        reason: data.reason,
        patientId: data.patientId,
        visitInstances: data.visitInstances,
        caregiverId: data.caregiverId ?? null,
        visitBroadcasts: data.visitBroadcasts ?? [],
        assigneeId: data.assignedAgencyMemberId ?? null,
        commCenterTickets: data.commCenterTickets ?? [],
      });
    },
  });

  const addVisitAssignment = useMutation({
    mutationFn: (body: BodyOf<"post", "./assignment">) => api.post("./assignment", { body }),
    onSuccess: ({ id }) => {
      onClose();
      props.onAdd(id);
    },
    onError: (error) => {
      toast({
        title: `Error while adding visit assignment`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Modal isOpen={props.isOpen} size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New visit assignment</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={form.onSubmit}>
          <NewVisitAssignmentModalBody form={form} />

          <ModalFooter>
            <Button colorScheme="blue" isLoading={addVisitAssignment.isPending} type="submit">
              Create
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
