import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";
import { TelephonyCallId } from "../../shared/schema/schema";

export default createQueryGroup(({ api }) => ({
  inboundCallEntityOptions: (callId: TelephonyCallId) =>
    queryOptions({
      queryKey: ["call-center", "calls", callId, "inbound_call_entity_options"],
      queryFn: async () => {
        const { entityOptions } = await api.get(
          "./telephony/calls/:callId/inbound_call_entity_options",
          { path: { callId } }
        );
        return entityOptions;
      },
    }),
}));
