import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../../../shared/hooks/useApi";
import { AgencyMemberId, VisitAssignmentId } from "../../../../../shared/schema/schema";
import { GET_VISIT_ASSIGNMENTS_QUERY } from "../visit-assignment.graphql";

export function useUpdateVisitAssignmentMutation() {
  const { api, queries } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const updateVisitAssignment = useMutation({
    mutationFn: (params: {
      id: VisitAssignmentId;
      body: { assigneeId: AgencyMemberId | null };
    }) => {
      return api.post("./assignment/:visitAssignmentId", {
        body: params.body,
        path: {
          visitAssignmentId: params.id,
        },
      });
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(queries.visit.assignments.get(id));
      queryClient.invalidateQueries({ queryKey: [GET_VISIT_ASSIGNMENTS_QUERY] });
      toast({
        position: "top-right",
        status: "success",
        title: "Visit assignment updated",
      });
    },
  });

  return updateVisitAssignment;
}
