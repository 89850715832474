import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { usePageFetchingObserver } from "../../../../shared/hooks/usePageIndication";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../../shared/query-keys";
import { PatientId } from "../../../../shared/schema/schema";
import IntakeDashboardTabView from "./IntakeDashboardTabView";
import useIntakeDashboard from "./hooks/useIntakeDashboard";
import useIntakeDashboardFilters from "./hooks/useIntakeDashboardFilters";

const IntakeDashboardRouteTab = () => {
  const queryClient = useQueryClient();
  const filters = useIntakeDashboardFilters();

  useSocketEvent({
    key: "NewIntakePatientCreated",
    onEvent: () => queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.search.K }),
  });

  const { dashboardQueryOptions } = useIntakeDashboard({
    filters: filters,
  });
  const { stateService } = useRouter();

  usePageFetchingObserver(dashboardQueryOptions);

  const goToPatientIntakeProfile = (
    event: React.MouseEvent<HTMLTableRowElement>,
    patientId: PatientId
  ) => {
    const isNewTab = event.ctrlKey || event.metaKey;

    isNewTab
      ? window.open(`/app/patients/patient-intake/${patientId}`)
      : stateService.go("app.patients.intake.dashboard.profile", {
          patientId,
        });
  };

  return (
    <IntakeDashboardTabView filters={filters} onClickDashboardRow={goToPatientIntakeProfile} />
  );
};

export default IntakeDashboardRouteTab;
