import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Messages, ResponseOf } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { AgencyMemberId } from "../../../../../shared/schema/schema";
import { IntakeDataRow } from "../../../components/IntakeDashboardTable";
import { useIntakeAgencyMemberCallsOrder } from "./useIntakeAgencyMemberCallsOrder";
import { useIntakeCallsOrder } from "./useIntakeCallsOrder";
import { IntakeDashboardFilters } from "./useIntakeDashboardFilters";

export default function useIntakeDashboard(params: { filters: IntakeDashboardFilters }) {
  const { queries } = useApi();
  const queryClient = useQueryClient();
  const dashboardQueryOptions = queries.intake.dashboard(params.filters.serverSide.params.toJSON());
  const callsOrder = useIntakeCallsOrder();
  const agencyMemberCallsOrder = useIntakeAgencyMemberCallsOrder();

  const tableQuery = useQuery({
    ...dashboardQueryOptions,
    select: (data) =>
      data.patients.map((p) => toDataRow(p, callsOrder.data ?? {}, agencyMemberCallsOrder)),
  });

  const handleRefresh = () => {
    queryClient.invalidateQueries(dashboardQueryOptions);
    queryClient.invalidateQueries(queries.intake.callsOrder());
  };

  return {
    tableQuery: tableQuery,
    dashboardQueryOptions: dashboardQueryOptions,
    refresh: handleRefresh,
  };
}

function toDataRow(
  row: Messages["IntakePatientDashboardDetails"],
  orderedCalls: ResponseOf<"get", "./patient_intake_calls_order">,
  agencyMemberOrderedCalls: Map<AgencyMemberId, Messages["IntakeCallOrder"][]>
): IntakeDataRow {
  const dataRow: IntakeDataRow = {
    ...row,
    entity: {
      displayId: row.displayId,
      fullName: row.firstName + " " + row.lastName,
      gender: row.gender,
      id: row.id,
      status: row.patientStatus,
      type: "Patient",
      contactDetails: null,
      selfServe: row.intakeFlowType === "Self Serve",
      wasSelfServe: row.wasSelfServe,
    },
    currentQueue: row.currentCallQueue,
    assigneeOrder: "No Order",
  };
  for (const key of Object.keys(orderedCalls)) {
    const calls = orderedCalls[key];
    const call = calls.find((call) => call.patientId === row.id);
    if (call !== undefined) {
      dataRow[`Team-${key}`] = call;
      if (call.assignedAgencyMemberId !== null) {
        const agencyMemberCallsOrderIndex = agencyMemberOrderedCalls
          .get(call.assignedAgencyMemberId)
          ?.findIndex((callOrder) => callOrder.id === call.id);
        if (agencyMemberCallsOrderIndex !== undefined) {
          dataRow.assigneeOrder = `${agencyMemberCallsOrderIndex + 1}`;
        }
      }
      break;
    }
  }
  return dataRow;
}
