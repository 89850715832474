import { AddIcon, CalendarIcon, ChatIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Circle,
  Flex,
  InputGroup,
  InputRightElement,
  Tag,
  TagLabel,
  TagLeftIcon,
} from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import { createColumnHelper } from "@tanstack/react-table";
import { useRouter } from "@uirouter/react";
import { isNonEmptyArray } from "../../../../../core/api/array-utils";
import DataTable from "../../../../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../../../../shared/components/DataTable/useGraphQLDataTable";
import RangeDatePicker from "../../../../../shared/components/DatePicker/RangeDatePicker";
import EntityCard from "../../../../../shared/components/EntityCard";
import Select from "../../../../../shared/components/Select";
import { createFilters } from "../../../../../shared/hooks/useFilters";
import RaiseHandIcon from "../../../../../shared/icons/RaiseHandIcon";
import {
  GetVisitAssignmentsQuery,
  GetVisitAssignmentsQueryVariables,
  PermissionControlledField,
  VisitAssignmentReason,
  VisitAssignmentStatus,
} from "../../../../../shared/schema/gql/graphql";
import { fmapIn } from "../../../../../shared/utils";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../../../shared/utils/duration-formatter";
import { getFullName } from "../../../../../shared/utils/get-full-name";
import { EntitySelect } from "../../../../workflow/components/EntityFormControl";
import { formatAssignmentReason } from "../assignments.utils";
import { GET_VISIT_ASSIGNMENTS_QUERY } from "../visit-assignment.graphql";

type TableRow = GetVisitAssignmentsQuery["visitAssignmentsDistinct"]["nodes"][number];

const { createRangeDatePickerFilter } = createFilters<GetVisitAssignmentsQueryVariables>();

interface VisitAssignmentsTableProps {
  openNewVisitAssignmentModal: () => void;
}

export default function VisitAssignmentsTable(props: VisitAssignmentsTableProps) {
  const { stateService } = useRouter();
  const { dataTableProps, globalFilters, setFilter } = useGraphQLDataTable({
    document: GET_VISIT_ASSIGNMENTS_QUERY,
    connection: "visitAssignmentsDistinct",
    columns: columns,
    enableColumnFilters: false,
    columnVisiblity: {},
    initialSorting: [
      { id: "lastUnreadTicketAt", desc: true },
      { id: "createdAt", desc: true },
    ],
    globalFilters: {
      initialState: {
        statuses: [VisitAssignmentStatus.Unresolved],
      },
    },
    trProps: (row) => {
      return {
        borderLeftWidth: "3px",
        borderLeftColor: row.lastUnreadTicketAt !== null ? "blue.500" : "white",
      };
    },
  });

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date from to",
    startDate: { name: "from", value: globalFilters.from ?? null },
    endDate: { name: "to", value: globalFilters.to ?? null },
    onChange: setFilter,
  });

  const filtersNode = (
    <>
      <InputGroup width="xs">
        <RangeDatePicker {...fromToFilter} inputProps={{ width: "full" }} />
        <InputRightElement>
          <CalendarIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>

      <Select
        label="Reasons"
        multiple={true}
        options={assignmentReasonsOptions}
        value={globalFilters.reasons ?? null}
        width="fit-content"
        onChange={(e) => setFilter("reasons", e)}
      />

      <Select
        label="Statuses"
        multiple={true}
        options={assignmentStatusOptions}
        value={globalFilters.statuses ?? null}
        onChange={(e) => setFilter("statuses", e)}
      />

      <EntitySelect
        input={{ type: "entity", entity: "Patient" }}
        label="Patients"
        multiple={true}
        renderUnselected="Patients"
        value={globalFilters.patientId?.in ?? null}
        onChange={(x) => setFilter("patientId", fmapIn(x))}
      />

      <EntitySelect
        hardFilters={{
          visitAssignmentAvailableOnly: true,
        }}
        input={{ type: "entity", entity: "Agency Member" }}
        label="Assignee"
        multiple={true}
        renderUnselected="Assignee"
        value={globalFilters.assignedAgencyMemberId ?? null}
        onChange={(x) => setFilter("assignedAgencyMemberId", x)}
      />
    </>
  );

  const actionNode = (
    <Button
      aria-label="New visit assignment"
      colorScheme="blue"
      leftIcon={<AddIcon />}
      onClick={props.openNewVisitAssignmentModal}
    >
      New visit assignment
    </Button>
  );

  return (
    <div>
      <DataTable
        {...dataTableProps}
        actionNode={actionNode}
        filterNode={filtersNode}
        onClickRow={(e, row) => {
          e.metaKey || e.ctrlKey
            ? window.open(`/app/visits/assignments?id=${row.id}`)
            : stateService.go("app.visitAssignments.assignment", {
                visitAssignmentId: row.original.id,
              });
        }}
      />
    </div>
  );
}

const { accessor, display } = createColumnHelper<TableRow>();

const columns = [
  accessor("id", {
    header: "ID",
    meta: { gqlSortKey: "id" },
  }),
  accessor("patient", {
    header: "Patient",
    meta: { gqlSortKey: "patientId" },
    cell: ({ getValue }) => {
      const { id, status, displayId, gender } = getValue();
      return (
        <Box w="fit-content">
          <EntityCard
            boxProps={{ maxWidth: "fit-content" }}
            entity={{
              type: "Patient",
              fullName: getFullName(getValue()),
              status: status,
              id: id,
              displayId: displayId ?? null,
              gender: gender ?? null,
              contactDetails: null,
            }}
          />
        </Box>
      );
    },
  }),
  accessor("caregiver", {
    header: "Caregiver",
    meta: { gqlSortKey: "caregiverId" },
    cell: ({ getValue }) => {
      const caregiver = getValue();
      if (caregiver === null) {
        return null;
      }

      const { id, status, displayId, avatarUrl } = caregiver;
      return (
        <EntityCard
          boxProps={{ maxWidth: "fit-content" }}
          entity={{
            type: "Caregiver",
            fullName: getFullName(caregiver),
            status: status,
            id: id,
            displayId: displayId ?? null,
            photoUrl: avatarUrl ?? null,
          }}
        />
      );
    },
  }),
  accessor("assignee", {
    header: "Assignee",
    meta: { gqlSortKey: "assignee" },
    cell: ({ getValue }) => {
      const assignee = getValue()?.agencyMember ?? null;

      return assignee === null ? null : (
        <EntityCard
          boxProps={{ maxWidth: "fit-content" }}
          entity={{
            type: "Agency Member",
            fullName: assignee.full_name,
            id: assignee.id,
            photoUrl: assignee.photoUrl,
          }}
        />
      );
    },
  }),
  accessor("lastUnreadTicketAt", {
    header: "Last unread message",
    meta: { gqlSortKey: "lastUnreadTicketAt" },
    sortUndefined: "last",
    cell: (cell) => {
      const value = cell.getValue();

      if (value === null) {
        return null;
      }

      return (
        <Flex align="center" gap={2}>
          <Circle bg="blue.500" size={1.5} />
          {durationFormatter.relativeFrom(value).formatted}
        </Flex>
      );
    },
  }),
  display({
    id: "unresolvedCount",
    header: "Unresolved count",
    cell: ({ row }) => row.original.unresolvedCount,
  }),
  display({
    id: "unreadCount",
    header: "Unread messages",
    cell: ({ row }) => {
      const unreadCount = row.original.tickets.nodes.reduce(
        (x, { ticket }) => x + ticket.unreadCount,
        0
      );

      return (
        <Tag
          colorScheme={unreadCount > 0 ? "blue" : undefined}
          opacity={unreadCount > 0 ? 1 : 0.3}
          size="lg"
          variant="outline"
        >
          <TagLeftIcon as={ChatIcon} boxSize="12px" />
          <TagLabel>{unreadCount}</TagLabel>
        </Tag>
      );
    },
  }),
  display({
    id: "awaitingAssignment",
    header: "Awaiting assignment",
    cell: ({ row }) => {
      const awaitingCount = row.original.visitBroadcasts.nodes.reduce((acc, broadcast) => {
        return acc + broadcast.visitBroadcast.engagements.nodes.length;
      }, 0);

      return (
        <Tag
          colorScheme={awaitingCount > 0 ? "orange" : undefined}
          opacity={awaitingCount > 0 ? 1 : 0.3}
          size="lg"
          variant="outline"
        >
          <TagLeftIcon as={RaiseHandIcon} boxSize="14px" />
          <TagLabel>{awaitingCount}</TagLabel>
        </Tag>
      );
    },
  }),
  accessor("reason", {
    header: "Reason",
    meta: { gqlSortKey: "_reason" },
    cell: ({ getValue }) => formatAssignmentReason(getValue()),
  }),
  accessor("status", { header: "Status", meta: { gqlSortKey: "_status" } }),
  accessor((x) => x.visitInstances.nodes.length, { id: "totalInstances", header: "Total visits" }),
  accessor((x) => x.visitBroadcasts.nodes.length, {
    id: "totalBroadcasts",
    header: "Total broadcasts",
  }),
  accessor("visitInstances.nodes", {
    header: "Time range (start times)",
    cell: ({ getValue }) => {
      const visitInstances = getValue();

      if (!isNonEmptyArray(visitInstances)) {
        return null;
      }

      let earliest: LocalDateTime = LocalDateTime.MAX;
      let latest: LocalDateTime = LocalDateTime.MIN;

      for (const visitInstance of visitInstances) {
        fmapProtected(visitInstance.visitInstance.startTimeLocal, (startTime) => {
          earliest = startTime.isBefore(earliest) ? startTime : earliest;
          latest = startTime.isAfter(latest) ? startTime : latest;
        });
      }

      if (earliest.equals(latest)) {
        fmapProtected(visitInstances[0].visitInstance.endTimeLocal, (endTime) => {
          latest = endTime;
        });
      }

      return dateFormatter.toDateTimeRange(earliest, latest);
    },
  }),
  accessor("createdAt", {
    header: "Reported at",
    meta: { gqlSortKey: "createdAt" },
    cell: ({ getValue }) => dateFormatter.toDateTime(getValue()),
  }),
];

function fmapProtected<T, U>(field: PermissionControlledField<T>, fn: (value: T) => U): U | null {
  if (field.value.type === "Value") {
    return fn(field.value.value);
  }

  return null;
}

const assignmentReasonsOptions: { value: VisitAssignmentReason; label: string }[] = Object.values(
  VisitAssignmentReason
).map((reason) => ({
  value: reason,
  label: formatAssignmentReason(reason),
}));

const assignmentStatusOptions: { value: VisitAssignmentStatus; label: string }[] = [
  { value: VisitAssignmentStatus.Resolved, label: "Resolved" },
  { value: VisitAssignmentStatus.Unresolved, label: "Unresolved" },
];
