import { useQuery } from "@tanstack/react-query";
import useApi from "../../../../../shared/hooks/useApi";
import { useToast } from "@chakra-ui/react";
import React from "react";

export function useIntakeCallsOrder() {
  const { queries } = useApi();
  const callsOrderQuery = useQuery(queries.intake.callsOrder());
  const toast = useToast();

  React.useEffect(() => {
    if (callsOrderQuery.isError) {
      toast({
        position: "top-right",
        status: "warning",
        title: "Failed to load intake calls order",
      });
    }
  }, [callsOrderQuery.isError, toast]);

  return callsOrderQuery;
}
