import { Flex, FormControl, FormLabel, Input, Skeleton, StyleProps, Text } from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { partition } from "lodash";
import React from "react";
import { Messages, ResponseOf } from "../../../core/api";
import Select from "../../../shared/components/Select";
import useApi from "../../../shared/hooks/useApi";
import { IntakeStatusId, IntakeTrackId } from "../../../shared/schema/schema";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";

type IntakeProfileSelectProps<TValue> = {
  isEditable: boolean;
  isRequired: boolean;
  options: TValue[];
  label: string;
  currValue: TValue;
  styleProps?: StyleProps;
  onChange: (value: TValue | null) => void;
};

type IntakeStatusSelectProps = IntakeProfileSelectProps<Messages["IntakeStatus"]>;
type IntakeTrackSelectProps = IntakeProfileSelectProps<Messages["PatientIntakeTrack"]>;

type IntakePatientProfileStatusAndTrackPickerProps = {
  statusSelectProps: IntakeStatusSelectProps;
  trackSelectProps: IntakeTrackSelectProps;

  intakeTracks: ResponseOf<"get", "./patient_intake/intake_tracks">["tracks"];
  currentTrack: Messages["IntakePatientProfile"]["track"];
};

const getActiveIntakeStatusId = (statuses: IntakeStatusSelectProps["options"]) => {
  const activeStatus = statuses.find((status) => status.code === "ACTIVE_INTAKE");
  if (activeStatus === undefined) {
    console.error("Active intake status not found");
  }
  return activeStatus?.id;
};

const getRelevantStatusesByCurrentTrack = (
  data: ResponseOf<"get", "./patient_intake/statuses_and_tracks">["items"],
  options: IntakeStatusSelectProps["options"],
  currentTrackId: IntakeTrackId
) => {
  const allChildrenStatusOptions = options.filter((option) => option.parentId !== null);
  const activeIntakeStatusId = getActiveIntakeStatusId(options);

  let activeIntakeStatusesIdsSet = new Set<IntakeStatusId>();
  const [activeIntakeStatuses, otherStatuses] = partition(
    allChildrenStatusOptions,
    (status) => status.parentId === activeIntakeStatusId
  );
  const activeIntakeStatusesIds = activeIntakeStatuses.map((status) => status.id);
  activeIntakeStatusesIdsSet = new Set(activeIntakeStatusesIds);

  const activeIntakeStatusesInCurrentTrack = data
    .filter((status) => {
      if (activeIntakeStatusesIdsSet.has(status.statusId)) {
        return status.trackId === currentTrackId;
      }
      return false;
    })
    .map((status) => status.statusId);
  const statusesInCurrentTrackSet = new Set(activeIntakeStatusesInCurrentTrack);

  const retActiveIntakeStatuses = allChildrenStatusOptions.filter((option) =>
    statusesInCurrentTrackSet.has(option.id)
  );
  return [...retActiveIntakeStatuses, ...otherStatuses];
};

const getRelevantParentStatuses = (
  relevantStatuses: ReturnType<typeof getRelevantStatusesByCurrentTrack>,
  options: IntakeStatusSelectProps["options"]
) => {
  const relevantParentStatusesIds = relevantStatuses.map((status) => status.parentId);
  const relevantParentStatusesIdsSet = new Set(relevantParentStatusesIds);
  const relevantParentStatuses = options.filter((option) =>
    relevantParentStatusesIdsSet.has(option.id)
  );
  return relevantParentStatuses;
};

export default function IntakePatientProfileStatusAndTrackPicker(
  props: IntakePatientProfileStatusAndTrackPickerProps
) {
  const { api } = useApi();
  const statusesAndTracks = useQuery({
    queryKey: ["patient_intake", "statuses_and_tracks"],
    queryFn: () => api.get("./patient_intake/statuses_and_tracks", {}),
    select: (data) => data.items,
    placeholderData: keepPreviousData,
  });

  switch (statusesAndTracks.status) {
    case "pending":
      return <IntakePatientProfileStatusPickerByTrackShimmer />;
    case "error":
      return <Text color="red">Error - cannot load IntakePatientProfileStatusPicker</Text>;
    case "success":
      return (
        <IntakePatientProfileStatusAndTrackPickerInner
          {...props}
          statusesAndTracksQueryData={statusesAndTracks.data}
        />
      );
  }
}

function IntakePatientProfileStatusAndTrackPickerInner(
  props: IntakePatientProfileStatusAndTrackPickerProps & {
    statusesAndTracksQueryData: ResponseOf<"get", "./patient_intake/statuses_and_tracks">["items"];
  }
) {
  const [currentParentStatus, setParentStatus] = React.useState(
    props.statusSelectProps.options.find(
      (x) => x.id === props.statusSelectProps.currValue?.parentId
    )!
  );
  const relevantStatuses = getRelevantStatusesByCurrentTrack(
    props.statusesAndTracksQueryData,
    props.statusSelectProps.options,
    props.currentTrack.id
  );
  const relevantParentStatuses = getRelevantParentStatuses(
    relevantStatuses,
    props.statusSelectProps.options
  );

  const handleTrackChange = (track: Messages["PatientIntakeTrack"] | null) => {
    props.trackSelectProps.onChange(track);

    const relevantStatuses = getRelevantStatusesByCurrentTrack(
      props.statusesAndTracksQueryData,
      props.statusSelectProps.options,
      track?.id ?? props.currentTrack.id
    );
    const isCurrValueInRelevantStatuses = relevantStatuses.some(
      (status) => status.id === props.statusSelectProps.currValue?.id
    );

    const status = isCurrValueInRelevantStatuses
      ? props.statusSelectProps.currValue
      : relevantStatuses[0];
    const parent = props.statusSelectProps.options.find((x) => x.id === status.parentId)!;
    props.statusSelectProps.onChange(status);
    setParentStatus(parent);
  };

  return (
    <Flex direction="row" gap={4}>
      <IntakePatientProfileTrackPicker
        {...props.trackSelectProps}
        currentPatientTrack={props.currentTrack}
        onChange={handleTrackChange}
      />
      <IntakePatientProfileStatusPicker
        {...props.statusSelectProps}
        currentParentStatus={currentParentStatus}
        options={[...relevantStatuses, ...relevantParentStatuses]}
        onChangeParentStatus={setParentStatus}
      />
    </Flex>
  );
}

export function IntakePatientProfileStatusPicker(
  props: IntakeStatusSelectProps & {
    currentParentStatus: Messages["IntakeStatus"];
    onChangeParentStatus: (status: Messages["IntakeStatus"]) => void;
  }
) {
  const parents = props.options.filter((x) => x.parentId === null);
  const subStatuses = props.options.filter((x) => x.parentId === props.currentParentStatus.id);

  const handleChangeParentStatus = (newId: IntakeStatusId | undefined) => {
    const option = parents.find((option) => option.id === newId) ?? parents[0];
    props.onChangeParentStatus(option);
    props.onChange(props.options.filter((x) => x.parentId === option.id)[0]);
  };

  const handleChangeSubStatus = (newId: IntakeStatusId | undefined) => {
    const option = subStatuses.find((option) => option.id === newId) ?? props.options[0];
    props.onChange(option);
  };

  return (
    <FormControl isRequired={props.isEditable ? props.isRequired : false} {...props.styleProps}>
      <FormLabel>{props.label}</FormLabel>
      {props.isEditable ? (
        <Flex direction="column" gap={4}>
          {/* Primary status */}
          <Select
            allowUnselect={false}
            label="Primary Status"
            multiple={false}
            options={parents.map((option) => ({
              value: option.id,
              label: option.status,
            }))}
            searchable={true}
            value={props.currentParentStatus.id}
            onChange={handleChangeParentStatus}
          />
          {/* Sub status */}
          <Select
            allowUnselect={false}
            label="Sub Status"
            multiple={false}
            options={subStatuses.map((option) => ({
              value: option.id,
              label: option.status,
            }))}
            searchable={true}
            value={props.currValue?.id ?? null}
            onChange={handleChangeSubStatus}
          />
        </Flex>
      ) : (
        <Input isDisabled={!props.isEditable} value={props.currValue.status} />
      )}
    </FormControl>
  );
}

function IntakePatientProfileTrackPicker(
  props: IntakeTrackSelectProps & {
    currentPatientTrack: Messages["IntakePatientProfile"]["track"];
  }
) {
  const handleChangeIntakeTrack = (trackId: IntakeTrackId) => {
    const foundTrack = props.options.find((track) => track.id === trackId);
    return props.onChange(foundTrack ?? null);
  };

  return (
    <IntakePatientProfileDropdown
      currValue={{
        value: `${props.currentPatientTrack.id}`,
        label: props.currentPatientTrack.name,
      }}
      isEditable={props.isEditable}
      isRequired={props.isEditable}
      label="Intake Track"
      options={props.options.map((option) => ({
        value: `${option.id}`,
        label: option.name,
      }))}
      onChange={(value) =>
        handleChangeIntakeTrack(
          value === null ? props.currentPatientTrack.id : IntakeTrackId.parse(parseInt(value))
        )
      }
    />
  );
}

function IntakePatientProfileStatusPickerByTrackShimmer() {
  return (
    <Flex direction="column">
      <FormLabel>Intake Status</FormLabel>
      <Flex direction="column" gap={4}>
        <Skeleton h={35} width={365} />
        <Skeleton h={35} width={365} />
      </Flex>
    </Flex>
  );
}
