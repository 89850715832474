import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  SortingState,
  Updater,
  getCoreRowModel,
  isFunction,
  useReactTable,
} from "@tanstack/react-table";
import { DefinitionNode, OperationDefinitionNode } from "graphql";
import React from "react";
import useApi from "../../hooks/useApi";
import { fmap } from "../../utils";
import { downloadCSVContent, mapDataTableToCSV } from "../../utils/data-table-csv-utils";
import { parseFromStorage } from "../../utils/storage";
import { sortingFns } from "../../utils/tanstack-table";
import { DataTableMetaButtonsProps } from "./DataTableMetaButtons";
import {
  DATA_TABLE_DEFAULT_PAGE_SIZE,
  mapColumnFiltersState,
  mapSortingState,
} from "./data-table.utils";
import useColumnVisibility, { ColumnVisibilityParams } from "./useColumnVisibility";
import { Exact, InputMaybe } from "../../schema/gql/graphql";
import { TableRowProps } from "@chakra-ui/react";

type DocumentData<
  TConnectionName extends string,
  TConnectionType extends Record<string, unknown>
> = {
  [key in TConnectionName]: {
    totalCount: number;
    nodes: TConnectionType[];
  };
};

type DocumentVariables = {
  order: any;
  limit: number;
  offset: number;
} & Record<string, unknown>;

type GetOrder<T> = T extends Exact<{
  order: Array<InputMaybe<{ field?: InputMaybe<infer TOrder extends string> }>>;
}>
  ? `${TOrder}`
  : never;

export default function useGraphQLDataTable<
  TDocumentData extends DocumentData<TConnectionName, TConnectionType>,
  TConnectionName extends string,
  TConnectionType extends Record<string, unknown>,
  TDocumentVariables extends DocumentVariables,
  TValue
>(params: {
  connection: TConnectionName;
  document: TypedDocumentNode<TDocumentData, Exact<TDocumentVariables>>;
  columnVisiblity: Omit<ColumnVisibilityParams<TConnectionType, TValue>, "columns">;
  initialSorting?: {
    id: GetOrder<TDocumentVariables>;
    desc: boolean;
  }[];
  globalFilters?: {
    storage?: { key: string; version: number };
    initialState?: Partial<TDocumentVariables>;
  };
  columns: ColumnDef<TConnectionType, any>[];
  enableColumnFilters?: boolean;
  trProps?: (row: TConnectionType) => TableRowProps;
}) {
  const { api } = useApi();

  const { columnOptions, columnVisibility, visibleColumns, setVisibleColumns } =
    useColumnVisibility({ ...params.columnVisiblity, columns: params.columns });

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: DATA_TABLE_DEFAULT_PAGE_SIZE,
  });

  const [sorting, setSorting] = React.useState<SortingState>(params.initialSorting ?? []);

  const $sorting = React.useMemo(() => {
    return mapSortingState(sorting, params.columns);
  }, [params.columns, sorting]);

  const { globalFilters, setGlobalFilters } = useGlobalFilters({
    document: params.document,
    storage: params.globalFilters?.storage,
    initialState: params.globalFilters?.initialState,
  });

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

  const $columnFilters = React.useMemo(() => {
    return mapColumnFiltersState(columnFilters, params.columns);
  }, [columnFilters, params.columns]);

  const setFilter = React.useCallback(
    <TKey extends keyof TDocumentVariables>(
      key: TKey,
      updater: Updater<TDocumentVariables[TKey] | undefined>
    ) => {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      setGlobalFilters((prev) => {
        const updateValue = isFunction(updater) ? updater(prev?.[key]) : updater;
        return { ...prev, [key]: updateValue ?? null } as TDocumentVariables;
      });
    },
    [setGlobalFilters]
  );

  const variables = {
    ...globalFilters,
    ...$columnFilters,
    order: $sorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
  };

  const query = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [params.document, variables],
    queryFn: () => api.graphql(params.document, { variables }),
  });

  const nodes = query.data?.[params.connection].nodes;
  const totalCount = query.data?.[params.connection].totalCount ?? 0;

  const pageCount = React.useMemo(() => {
    return Math.ceil(totalCount / pagination.pageSize);
  }, [pagination.pageSize, totalCount]);

  const exportMutation = useMutation({
    mutationFn: () => {
      return api.graphql(params.document, {
        variables: { ...variables, limit: 100000, offset: 0 },
      });
    },
    onSuccess: (data) => {
      const rows = data[params.connection].nodes;

      const headers = table
        .getHeaderGroups()
        .flatMap((group) => group.headers)
        .filter((x) => !x.id.startsWith("_"));

      const csv = mapDataTableToCSV({ rows, headers });

      downloadCSVContent(csv);
    },
  });

  const table = useReactTable({
    data: React.useMemo(() => nodes ?? [], [nodes]),
    columns: params.columns,
    pageCount: pageCount,
    state: {
      columnVisibility: columnVisibility,
      pagination: pagination,
      globalFilter: globalFilters,
      columnFilters: columnFilters,
      sorting: sorting,
    },
    enableColumnFilters: params.enableColumnFilters ?? true,
    manualPagination: true,
    sortingFns: sortingFns,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: React.useCallback(
      (updater: Updater<TDocumentVariables>) => {
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        setGlobalFilters(updater);
      },
      [setGlobalFilters]
    ),
    onColumnFiltersChange: React.useCallback((updater: Updater<ColumnFiltersState>) => {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      setColumnFilters(updater);
    }, []),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const metaButtonProps: DataTableMetaButtonsProps = {
    columnOptions: columnOptions,
    onChangeVisibleColumns: setVisibleColumns,
    visibleColumns: visibleColumns,
    isRefreshing: query.isFetching,
    onClickRefresh: query.refetch,
    isExporting: exportMutation.isPending,
    onClickExport: exportMutation.mutate,
  };

  return {
    table,
    query,
    exportMutation,
    globalFilters,
    setFilter,
    metaButtonProps,
    dataTableProps: {
      metaButtonProps,
      isLoading: query.isPending,
      table: table,
      trProps: params.trProps,
    },
  };
}

function useGlobalFilters<
  TDocumentData extends DocumentData<TConnectionName, TConnectionType>,
  TConnectionName extends string,
  TConnectionType extends Record<string, unknown>,
  TDocumentVariables extends DocumentVariables
>(params: {
  document: TypedDocumentNode<TDocumentData, TDocumentVariables>;
  storage?: { key: string; version: number };
  initialState?: Partial<TDocumentVariables>;
}) {
  const initialLoadRef = React.useRef(true);

  const storageRef = React.useRef(params.storage);
  const storageKey = React.useMemo(() => {
    return fmap(storageRef.current, (x) => JSON.stringify(["table-filters", x]));
  }, []);

  const [globalFilters, setGlobalFilters] = React.useState<TDocumentVariables>(() => {
    const initialState: TDocumentVariables = {
      ...(Object.fromEntries(
        getTypedDocumentNodeVariableNames(params.document).map((x) => [x, null])
      ) as TDocumentVariables),
      ...params.initialState,
    };

    if (storageKey === null) {
      return initialState;
    }

    return {
      ...initialState,
      ...parseFromStorage({ key: storageKey, storage: localStorage }),
    };
  });

  React.useEffect(() => {
    if (initialLoadRef.current) {
      initialLoadRef.current = false;
      return;
    }

    if (storageKey === null) {
      return;
    }

    localStorage.setItem(storageKey, JSON.stringify(globalFilters));
  }, [globalFilters, storageKey]);

  return {
    globalFilters,
    setGlobalFilters,
  };
}

function isOperationDefinition(node: DefinitionNode): node is OperationDefinitionNode {
  return node.kind === "OperationDefinition";
}

function getTypedDocumentNodeVariableNames<TDocumentVariables>(
  document: TypedDocumentNode<unknown, TDocumentVariables>
): (keyof TDocumentVariables)[] {
  const definiton = document.definitions.find(isOperationDefinition);

  if (definiton === undefined) {
    throw new Error("Invalid document. Expected query operation");
  }

  return (
    definiton.variableDefinitions?.map((x) => x.variable.name.value as keyof TDocumentVariables) ??
    []
  );
}
