interface CreateMfModalParams {
  variant?: "primary" | "info" | "warning" | "danger";
  subject?: string;
  message?: string;
  options?: { id: number; label: string }[];
  checkboxes?: { id: number; label: string; checked: boolean }[];
  optionIsRequired?: boolean;
  showInput?: boolean;
  inputPlaceholder?: string;
  inputIsRequired?: boolean;
  inputType?: "text" | "textarea";
  customTemplate?: string;
  data?: any;
  cancelLabel?: string;
  extraActionLabel?: string;
  extraAction?: () => void;
  confirmLabel?: string;
  size?: string;
  layoutOrder?: ("message" | "options" | "checkboxes" | "input" | "customTemplate")[];
  preventClose?: boolean;
  preventBackdropClose?: boolean;
  hideCancelButton?: boolean;
  onConfirm?: (state: MfModalState) => void;
  onCancel?: (state: MfModalState) => void;
  onComplete?: (state: MfModalState) => void;
  onExtraAction?: (state: MfModalState) => void;
}

interface MfModalState extends CreateMfModalParams {
  inputModel?: string;
  selectedOption?: number;
  isLoading?: boolean;
}

/**
 * An example usage of this modal:
 * 
 * const modal = mfModal.create({
 *      subject: "Delete item",
 *      options: [
 *        { id: 1, label: "Delete selected items (4)" },
 *        { id: 2, label: "Delete selected items going forward" },
 *        { id: 3, label: "Delete selected items until a specific date" },
 *      ],
 *      message: `Are you sure you want to delete 4 visits?\n All of your deleted visits will be saved on your calendar.`,
 *      layoutOrder: ["message", "options"],
 *      cancelLabel: "Cancel",
 *      confirmLabel: "Delete",
 *      onConfirm: ({ selectedOption }) => {
 *        modal.setLoading(true);
 *        $timeout(() => {
 *          if (selectedOption) {
 *            modal.close();
 *            return;
 *          }

 *          modal.update({
 *            isLoading: false,
 *            message: "Please select an option!",
 *          });
 *        }, 1000);
 *      },
 *      onCancel: () => console.log("Canceled"),
 *      onComplete: () => console.log("Complete"),
 *    });
 */

//! @ngInject
export function MfModalFactory($uibModal: ng.ui.bootstrap.IModalService) {
  return {
    create({
      variant,
      subject,
      message,
      options,
      optionIsRequired,
      showInput,
      inputPlaceholder,
      inputIsRequired,
      inputType,
      customTemplate,
      data,
      cancelLabel,
      extraActionLabel,
      extraAction,
      confirmLabel,
      size,
      layoutOrder,
      preventClose,
      preventBackdropClose,
      hideCancelButton,
      onConfirm,
      onCancel,
      onComplete,
      onExtraAction,
      checkboxes,
    }: CreateMfModalParams) {
      const state: MfModalState = {
        variant: variant || "info",
        subject,
        message,
        options,
        optionIsRequired,
        checkboxes,
        cancelLabel,
        extraActionLabel,
        confirmLabel,
        hideCancelButton: hideCancelButton || false,
        extraAction: extraAction,
        onConfirm,
        onCancel,
        onComplete,
        onExtraAction,
        isLoading: false,
        selectedOption: undefined,
        showInput,
        inputIsRequired,
        inputType,
        customTemplate,
        data,
        inputPlaceholder: inputPlaceholder || "",
        inputModel: undefined,
      };

      const modal = $uibModal.open({
        templateUrl: "admin/views/mf-modal.html",
        size: size || "md",
        windowClass: "center-center mf-modal-window",
        backdrop: preventClose === true || preventBackdropClose === true ? "static" : true,
        keyboard: preventClose === true ? false : true,
        resolve: {
          state: () => state,
        },
        controller: ($scope) => {
          $scope.onClickConfirm = () => {
            if (onConfirm) {
              onConfirm(state);
            }

            if (onComplete) {
              onComplete(state);
            }
          };

          $scope.onClickExtraAction = () => {
            if (onExtraAction) {
              onExtraAction(state);
            }

            if (onComplete) {
              onComplete(state);
            }
          };

          $scope.onClickCancel = () => {
            if (!preventClose) {
              modal.close();
            }

            if (onCancel) {
              onCancel(state);
            }

            if (onComplete) {
              onComplete(state);
            }
          };

          $scope.layoutOrder = layoutOrder || ["message", "options", "input"];
          $scope.optionsOrder = $scope.layoutOrder.indexOf("options");
          $scope.messageOrder = $scope.layoutOrder.indexOf("message");
          $scope.inputOrder = $scope.layoutOrder.indexOf("input");
          $scope.customTemplateOrder = $scope.layoutOrder.indexOf("customTemplate");
          $scope.modal = state;
        },
      });

      return {
        setLoading: (isLoading) => (state.isLoading = isLoading),
        update: (newPayload) => {
          return Object.entries(newPayload).forEach(([key, value]) => (state[key] = value));
        },
        close: () => modal.close(),
      };
    },

    createSimple({
      variant = "danger" as const,
      subject = "Error",
      message = "",
      confirmLabel = "OK",
    }) {
      const modal = this.create({
        variant: variant,
        subject: subject,
        message: message,
        layoutOrder: ["message"],
        confirmLabel: confirmLabel,
        hideCancelButton: true,
        onComplete: () => modal.close(),
      });
    },
  };
}

export type MfModalFactory = ReturnType<typeof MfModalFactory>;

MfModalFactory.$name = "mfModal";