import { useQuery } from "@tanstack/react-query";
import { tabId } from "../../../shared/consts/tab-id";
import useApi from "../../../shared/hooks/useApi";
import useReactBroadcastChannel from "../../../shared/hooks/useReactBroadcastChannel";
import { getPlivoClientUtils } from "../../../shared/utils/call-providers/plivo-call-provider";
import plivoClient from "../../../shared/utils/plivo-client";
import {
  AlreadyInACallError,
  AlreadyInACallOnAnotherTabError,
  PhoneProviderNotInitializedError,
  PhoneProviderNotRegisteredError,
} from "../call-center.errors";
import useActivePhoneProviderClientTab from "./useActivePhoneProviderClientTab";
import { customClientEvents } from "./usePhoneProviderListeners";

type Endpoint = {
  username: string;
  password: string;
  endpoint: string;
};

export type LogInfoState =
  | { type: "Initial" | "NotRegistered" }
  | {
      type: "LoggedInOnAnotherTab" | "InCallOnAnotherTab";
      tabId: string;
      endpoint: Endpoint;
    }
  | {
      type: "Idle" | "LoggingIn" | "LoggedIn" | "InCall" | "RequiresReLogin";
      endpoint: Endpoint;
    };

export default function usePhoneProvider() {
  const { queries } = useApi();
  const activeClientTab = useActivePhoneProviderClientTab();
  const sipEndpointsQuery = useQuery(queries.telephony.endpoints());
  const { broadcastEvent } = useReactBroadcastChannel();

  const log = (...args: unknown[]) => {
    console.log(`[plivo client]`, ...args);
  };

  const getState = (): LogInfoState => {
    if (sipEndpointsQuery.data === undefined) {
      return { type: "Initial" };
    }

    if (sipEndpointsQuery.data.primary === null) {
      return { type: "NotRegistered" };
    }

    if (activeClientTab.id !== null && !activeClientTab.isTabActive) {
      return {
        type: activeClientTab.activeCallId === null ? "LoggedInOnAnotherTab" : "InCallOnAnotherTab",
        tabId: activeClientTab.id,
        endpoint: sipEndpointsQuery.data.primary,
      };
    }

    const { isLoggingIn } = getPlivoClientUtils(plivoClient);

    if (isLoggingIn || plivoClient.isConnecting()) {
      return { type: "LoggingIn", endpoint: sipEndpointsQuery.data.primary };
    }

    if (plivoClient.isLoggedIn && plivoClient.isConnected() && plivoClient.isRegistered()) {
      return activeClientTab.activeCallId === null
        ? { type: "LoggedIn", endpoint: sipEndpointsQuery.data.primary }
        : { type: "InCall", endpoint: sipEndpointsQuery.data.primary };
    }

    if (plivoClient.isLoggedIn || plivoClient.isConnected() || plivoClient.isRegistered()) {
      return { type: "RequiresReLogin", endpoint: sipEndpointsQuery.data.primary };
    }

    return { type: "Idle", endpoint: sipEndpointsQuery.data.primary };
  };

  const logout = () => {
    log("Logging out");
    plivoClient.logout();
    activeClientTab.isTabActive && activeClientTab.setActiveTabId(null);
  };

  const login = async () => {
    const state = getState();
    log("Logging in", state, "plivo state", {
      isConnected: plivoClient.isConnected(),
      isConnecting: plivoClient.isConnecting(),
      isRegistered: plivoClient.isRegistered(),
      currentTabId: tabId,
    });

    switch (state.type) {
      case "Initial":
        throw new PhoneProviderNotInitializedError();

      case "NotRegistered":
        throw new PhoneProviderNotRegisteredError();

      case "LoggingIn": {
        const { waitForLogin } = getPlivoClientUtils(plivoClient);

        return waitForLogin(state.endpoint);
      }

      case "LoggedIn":
        return;

      case "InCall":
        throw new AlreadyInACallError();

      case "InCallOnAnotherTab":
        throw new AlreadyInACallOnAnotherTabError(state.tabId);

      case "RequiresReLogin":
      case "LoggedInOnAnotherTab":
      case "Idle": {
        if (state.type === "LoggedInOnAnotherTab") {
          broadcastEvent("PHONE_CLIENT:LOGOUT", { tabId: state.tabId });

          // Wait for the other tab to logout before logging in
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        if (state.type === "RequiresReLogin") {
          plivoClient.logout();
        }

        const { login: loginToPlivo } = getPlivoClientUtils(plivoClient);

        try {
          activeClientTab.setActiveTabId(tabId);

          await loginToPlivo(state.endpoint);
        } catch (error) {
          activeClientTab.isTabActive && activeClientTab.setActiveTabId(null);
          throw error;
        }
      }
    }
  };

  const answerIncomingCall = ({ destination }: { destination: string }) => {
    log("Answering incoming call", destination);

    for (const call of plivoClient.getIncomingCalls()) {
      if (call.extraHeaders["X-Ph-Call-Id"] === destination) {
        plivoClient.answer(destination, "reject");
        return;
      }
    }

    plivoClient.once("onIncomingCall", (_, params) => {
      log("Incoming call", params);

      // While there's almost zero-chance for answering the wrong call, we still check the call id
      // just to be safe
      if (params["X-Ph-Call-Id"] === destination) {
        plivoClient.answer(destination, "reject");
      }
    });
  };

  const startOutboundCall = ({ destination }: { destination: string }) => {
    log("Starting outbound call", destination);

    plivoClient.once("onIncomingCall", (_, params, { callUUID }) => {
      log("Incoming call", params);

      if (params["X-Ph-Mf-Call-Id"] === destination) {
        plivoClient.answer(callUUID, "ignore");
      }
    });
  };

  const hangupCall = () => {
    log("Hanging up call");

    try {
      plivoClient.hangup();
      plivoClient.getIncomingCalls().forEach((call) => plivoClient.reject(call.callUUID));
    } catch (e) {
      // Sometimes plivoClient.hangup fails (for instance, hanging up a call that was already hung up)
      // Instead of crashing the flow, we just log the error and continue
      console.error(e);
    }
  };

  const sendDtmfTone = (char: string) => {
    log("Sending DTMF tone", char);
    plivoClient.sendDtmf(char);
  };

  const muteCall = () => {
    log("Muting call");
    plivoClient.mute();
    window.dispatchEvent(new Event(customClientEvents.muteToggle));
  };

  const unmuteCall = () => {
    log("Unmuting call");
    plivoClient.unmute();
    window.dispatchEvent(new Event(customClientEvents.muteToggle));
  };

  const onLocalSilent = (callback: (res: unknown) => void) => {
    log("Listening for silent event");

    const callbackWithLog = (res: unknown) => {
      log("Silent event received", res);
      callback(res);
    };

    window.addEventListener(customClientEvents.silent, callbackWithLog);
    return () => window.removeEventListener(customClientEvents.silent, callbackWithLog);
  };

  const onLocalUnSilent = (callback: (res: unknown) => void) => {
    log("Listening for unsilent event");

    const callbackWithLog = (res: unknown) => {
      log("Unsilent event received", res);
      callback(res);
    };

    window.addEventListener(customClientEvents.unsilent, callbackWithLog);
    return () => window.removeEventListener(customClientEvents.unsilent, callbackWithLog);
  };

  const silentCall = () => {
    log("Silent call");

    const audioElement = plivoClient.audio.speakerDevices.media(
      "ringback"
    ) as HTMLAudioElement | null;

    if (audioElement) {
      audioElement.volume = 0;
      muteCall();
      window.dispatchEvent(new Event(customClientEvents.silent));
    }
  };

  const unSilentCall = () => {
    log("Unsilent call");

    const audioElement = plivoClient.audio.speakerDevices.media(
      "ringback"
    ) as HTMLAudioElement | null;

    if (audioElement) {
      audioElement.volume = 1;
      unmuteCall();
      window.dispatchEvent(new Event(customClientEvents.unsilent));
    }
  };

  return {
    activeTabId: activeClientTab.id,
    activeCallTabId: activeClientTab.activeCallId,
    setActiveCallId: activeClientTab.setActiveCallId,
    isActive: activeClientTab.id !== null,
    isActiveOnAnotherTab: activeClientTab.id !== null && !activeClientTab.isTabActive,
    isActiveOnCurrentTab: activeClientTab.isTabActive,
    login: login,
    logout: logout,
    answerIncomingCall: answerIncomingCall,
    startOutboundCall: startOutboundCall,
    hangupCall: hangupCall,
    sendDtmfTone: sendDtmfTone,
    muteCall: muteCall,
    unmuteCall: unmuteCall,
    onLocalSilent: onLocalSilent,
    onLocalUnSilent: onLocalUnSilent,
    silentCall: silentCall,
    unSilentCall: unSilentCall,
  };
}
