import React from "react";

import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { Table as ReactTable, Row, createColumnHelper } from "@tanstack/react-table";
import { useDidMount } from "rooks";
import { PatientStatus } from "../../../../../public/admin/scripts/messages/patient";
import { Messages, ResponseOf } from "../../../core/api";
import DataTable from "../../../shared/components/DataTable/DataTable";
import useQueryDataTable from "../../../shared/components/DataTable/useQueryDataTable";
import EntityCard, { PatientEntity } from "../../../shared/components/EntityCard";
import Dot02SIcon from "../../../shared/icons/Dot02SIcon";
import { PatientId } from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import useIntakeDashboard from "../pages/dashboard/hooks/useIntakeDashboard";
import { IntakeDashboardFilters } from "../pages/dashboard/hooks/useIntakeDashboardFilters";
import IntakeNextCallBadge from "./IntakeNextCallBadge";
import { useIntakeCallsOrder } from "../pages/dashboard/hooks/useIntakeCallsOrder";

const NEW_YORK_TZ = "America/New_York";
export type IntakeDataRow = Messages["IntakePatientDashboardDetails"] & {
  entity: PatientEntity;
  currentQueue: string;
  assigneeOrder: string;
  [intakeTeamName: `Team-${string}`]: Messages["IntakeCallOrder"];
};

const workflowStatusTextMap: Record<Messages["WorkflowInstance"]["status"]["name"], string> = {
  CANCELED: "Canceled",
  ERROR: "Error",
  IN_PROGRESS: "In Progress",
  SUCCESS: "Success",
};

function getWorkflowsColor(
  workflows: Messages["IntakePatientDashboardDetails"]["activeWorkflows"]
) {
  if (workflows.length === 0) {
    return "gray.400";
  }
  if (workflows.some((x) => x.status === "ERROR")) {
    return "red.400";
  }
  if (workflows.some((x) => x.status === "IN_PROGRESS")) {
    return "yellow.400";
  }
  return "green.300";
}

function makeAppointmentText<
  T extends {
    date: Instant | null;
    completed: boolean;
  }
>(val: T): string {
  const dateText =
    fmap(val.date, (v) =>
      dateFormatter.toDateTime(v, {
        timezone: NEW_YORK_TZ,
      })
    ) ?? undefined;

  const completedText = val.completed === true ? "Completed" : "Not Completed";
  return `${dateText !== undefined ? dateText + " -" : ""} ${completedText}`;
}

export interface IntakeDashbaordLocalFilters {
  incompletePatients: boolean;
}

interface Props {
  tableRef?: React.MutableRefObject<ReactTable<IntakeDataRow> | null>;
  filters: IntakeDashboardFilters;
  filterNode: React.ReactNode;
  actionNode: React.ReactNode;
  onClickDashboardRow: (event: React.MouseEvent<HTMLTableRowElement>, patientId: PatientId) => void;
}

export default function IntakeDashboardTable(props: Props) {
  const callsOrder = useIntakeCallsOrder();
  const { tableQuery } = useIntakeDashboard({
    filters: props.filters,
  });

  useDidMount(() => {
    if (props.tableRef !== undefined) {
      props.tableRef.current = table;
    }
  });

  const columns = React.useMemo(
    () =>
      createIntakeDashboardColumns({
        orderedCalls: callsOrder.data ?? {},
      }),
    [callsOrder]
  );

  const { dataTableProps, table } = useQueryDataTable({
    query: tableQuery,
    columns,
    columnVisiblity: {
      storage: { key: "patient-intake-dashboard", version: 3 },
      forceVisibleColumns: React.useMemo(() => ["entity"], []),
      initialSelected: [
        "intakeStatus",
        "assignedAgencyMember",
        "assigneeOrder",
        "nextCall",
        "activeWorkflows",
        "plans",
        "medicaidId",
        "lastCall",
        "track",
        "leadSource",
        "contactNames",
        "lastUpdatedAt",
        "createdAt",
        "currentQueue",
      ],
    },
    trProps: (row) => {
      return {
        borderLeftWidth: "3px",
        borderLeftColor: row.isIncompleteIntakeFlow ? "red.500" : "white",
        _hover: {
          td: {
            backgroundColor: row.isIncompleteIntakeFlow ? "red.100" : "blue.50",
          },
        },
        sx: {
          td: {
            backgroundColor: row.isIncompleteIntakeFlow ? "red.50" : "white",
          },
        },
      };
    },
    initialSorting: [
      {
        id: "lastUpdatedAt",
        desc: true,
      },
    ],
  });

  const handleClickDashboardRow = (
    event: React.MouseEvent<HTMLTableRowElement>,
    row: Row<IntakeDataRow>
  ) => {
    props.onClickDashboardRow(event, row.original.id);
  };

  return (
    <DataTable
      {...dataTableProps}
      actionNode={props.actionNode}
      filterNode={props.filterNode}
      onClickRow={handleClickDashboardRow}
    />
  );
}

function createIntakeDashboardColumns(params: {
  orderedCalls: ResponseOf<"get", "./patient_intake_calls_order">;
}) {
  const columnHelper = createColumnHelper<IntakeDataRow>();
  const columns = [
    columnHelper.accessor("entity", {
      cell: (info) => <EntityCard entity={info.getValue()} />,
      header: "Patient",
      meta: {
        csvFn: (x) => `${x.fullName} (${x.displayId})`,
      },
    }),
    columnHelper.accessor("intakeStatus", {
      cell: (info) => info.getValue().status ?? "Unknown",
      header: "Intake Status",
      meta: {
        csvFn: (x) => x.status ?? "Unknown",
      },
      sortingFn: (a, b) => {
        const aVal = a.original.intakeStatus.id;
        const bVal = b.original.intakeStatus.id;
        return bVal - aVal;
      },
    }),
    columnHelper.accessor("assignedAgencyMember", {
      header: "Assignee",
      cell: (info) =>
        fmap(info.getValue(), (x) => (
          <EntityCard
            entity={{
              type: "Agency Member",
              id: x.id,
              fullName: x.name,
              photoUrl: null,
            }}
          />
        )) ?? "Not Assigned",
      meta: {
        csvFn: (x) => x?.name ?? "Not Assigned",
      },
      sortingFn: (a, b) => {
        const aVal = a.original.assignedAgencyMember?.name ?? "";
        const bVal = b.original.assignedAgencyMember?.name ?? "";
        return aVal.localeCompare(bVal);
      },
    }),
    columnHelper.accessor("assigneeOrder", {
      header: "Assignee Order",
      cell: (row) => row.getValue(),
      sortingFn: (a, b) => {
        const aVal = a.original.assigneeOrder;
        const bVal = b.original.assigneeOrder;
        const aNum = parseInt(aVal, 10);
        const bNum = parseInt(bVal, 10);
        if (!isNaN(aNum) && !isNaN(bNum)) {
          return aNum - bNum;
        }

        return isNaN(aNum) ? 1 : isNaN(bNum) ? -1 : 0;
      },
    }),
    ...Object.keys(params.orderedCalls)
      .sort()
      .map((key) => {
        const keyName = `Team-${key}` as const;
        return columnHelper.accessor(keyName, {
          header: key,
          enableSorting: true,
          cell: (info) => {
            const call = info.row.original[keyName];

            return !call ? (
              <Text>No order</Text>
            ) : (
              <Text>{params.orderedCalls[key]?.findIndex((x) => x.id === call.id) + 1}</Text>
            );
          },
          meta: {
            csvFn: (_, row) => {
              const call = row[keyName];
              if (!call) {
                return "No Order";
              }
              return (params.orderedCalls[key]?.findIndex((x) => x.id === call.id) + 1).toString();
            },
          },
          sortingFn: (rowA, rowB) => {
            const rowACall = rowA.original[keyName];
            const rowBCall = rowB.original[keyName];
            if (!rowACall) {
              return rowBCall ? 0 : -1;
            }

            if (!rowBCall) {
              return 1;
            }

            return (
              params.orderedCalls[key]?.findIndex((x) => x.id === rowACall.id) -
              params.orderedCalls[key]?.findIndex((x) => x.id === rowBCall.id)
            );
          },
        });
      }),
    columnHelper.accessor("nextCall", {
      header: "Next Call Date",
      cell: (info) => {
        const nextCallDetails = info.row.original.nextCallDetails;
        const displayText2 = fmap(nextCallDetails, (v) => {
          if (v.callDate !== null) {
            return dateFormatter.toDateTime(v.callDate, { timezone: NEW_YORK_TZ });
          }
          if (v.manualLocalDateTime !== null) {
            return dateFormatter.toDateTime(v.manualLocalDateTime, { timezone: NEW_YORK_TZ });
          }
          return null;
        });

        return (
          <>
            <IntakeNextCallBadge nextCallDate={info.getValue()} />
            <Text>{displayText2 ?? ""}</Text>
            {nextCallDetails !== null && (
              <Flex fontSize={12} gap={3}>
                {nextCallDetails.boost && (
                  <Text color="red.400"> {nextCallDetails.boost.description} </Text>
                )}
                {nextCallDetails.burstPrevCall !== null && nextCallDetails.burstNum !== null ? (
                  <Text>{`${nextCallDetails.burstNum}/3 retries`}</Text>
                ) : null}
              </Flex>
            )}
          </>
        );
      },
      meta: {
        csvFn: (x) =>
          fmap(x, (v) => dateFormatter.toDateTime(v, { timezone: NEW_YORK_TZ })) ??
          "No Calls Were Scheduled",
      },
      sortingFn: "localDateTime",
    }),
    columnHelper.accessor("currentQueue", {
      header: "Current Queue",
      cell: (info) => {
        return <Text>{info.getValue()}</Text>;
      },
      sortingFn: (rowA, rowB) => {
        const queueNameA = rowA.original.currentQueue;
        const queueNameB = rowB.original.currentQueue;
        return queueNameA.localeCompare(queueNameB, undefined, { sensitivity: "base" });
      },
    }),
    columnHelper.accessor("activeWorkflows", {
      header: "Active Workflows",
      cell: (info) => {
        const workflows = info.getValue();
        const color = getWorkflowsColor(workflows);
        const workflowsRender = (
          <Flex direction="column">
            {workflows.map((workflow) => (
              <Box key={workflow.id}>
                {workflow.name}: {workflowStatusTextMap[workflow.status]}
              </Box>
            ))}
          </Flex>
        );
        return (
          <Tooltip hasArrow isDisabled={workflows.length === 0} label={workflowsRender}>
            <Box>
              <Dot02SIcon boxSize={10} color={color} />
              {workflows.length}
            </Box>
          </Tooltip>
        );
      },
      meta: {
        csvFn: (x) => x.length.toString(),
      },
      sortingFn: (rowA, rowB) =>
        rowA.original.activeWorkflows.length - rowB.original.activeWorkflows.length,
    }),
    columnHelper.accessor("plans", {
      cell: (info) =>
        info.getValue().length > 0
          ? info.getValue().length === 1
            ? info.getValue()[0]
            : `${info.getValue()[0]} (+${info.getValue().length - 1})`
          : "Unknown",
      meta: {
        csvFn: (x) => (x.length > 0 ? x.join(", ") : "Unknown"),
      },
      header: "Plans",
    }),
    columnHelper.accessor("patientStatus", {
      header: "Patient Status",
      cell: (info) => formatPatientStatus(info.getValue()) ?? "Unknown",
      meta: {
        csvFn: (x) => formatPatientStatus(x) ?? "Unknown",
      },
    }),
    columnHelper.accessor("medicaidId", {
      header: "Medicaid ID",
      cell: (info) => info.getValue() ?? "Unknown",
      meta: {
        csvFn: (x) => x ?? "Unknown",
      },
    }),
    columnHelper.accessor("lastCall", {
      header: "Last Call Date",
      cell: (info) => (
        <Text>
          {fmap(info.getValue(), (v) => dateFormatter.toDateTime(v, { timezone: NEW_YORK_TZ })) ??
            "No calls were made"}
        </Text>
      ),
      meta: {
        csvFn: (x) =>
          fmap(x, (v) => dateFormatter.toDateTime(v, { timezone: NEW_YORK_TZ })) ?? "No Calls Made",
      },
      sortingFn: "localDateTime",
    }),
    columnHelper.accessor("track.name", { header: "Intake Track" }),
    columnHelper.accessor("leadSource", {
      header: "Lead Source",
      cell: (info) => info.getValue() ?? "Unknown",
      meta: {
        csvFn: (x) => x ?? "Unknown",
      },
    }),
    columnHelper.accessor("medicaidStatus", {
      header: "Medicaid Status",
      cell: (info) => info.getValue()?.name ?? "Unknown",
      meta: {
        csvFn: (x) => x?.name ?? "Unknown",
      },
    }),
    columnHelper.accessor("medicareStatus", {
      header: "Medicare Status",
      cell: (info) => info.getValue() ?? "Unknown",
      meta: {
        csvFn: (x) => x ?? "Unknown",
      },
    }),
    columnHelper.accessor("NYIA1Status", {
      header: "NYIA #1",
      cell: (info) => <Text>{makeAppointmentText(info.getValue())}</Text>,
      meta: {
        csvFn: (x) => makeAppointmentText(x),
      },
    }),
    columnHelper.accessor("NYIA2Status", {
      header: "NYIA #2",
      cell: (info) => <Text>{makeAppointmentText(info.getValue())}</Text>,
      meta: {
        csvFn: (x) => makeAppointmentText(x),
      },
    }),
    columnHelper.accessor("UASStatus", {
      header: "UAS",
      cell: (info) => <Text>{makeAppointmentText(info.getValue())}</Text>,
      meta: {
        csvFn: (x) => makeAppointmentText(x),
      },
    }),
    columnHelper.accessor("contactNames", {
      header: "Contacts",
      cell: (info) => <Text>{info.getValue()}</Text>,
      meta: {
        csvFn: (x) => x ?? "None",
      },
    }),
    columnHelper.accessor("lastUpdatedAt", {
      header: "Last Updated At",
      cell: (info) => (
        <Text>{dateFormatter.toDateTime(info.getValue(), { timezone: NEW_YORK_TZ })}</Text>
      ),
      meta: {
        csvFn: (x) => dateFormatter.toDateTime(x, { timezone: NEW_YORK_TZ }),
      },
      sortingFn: "instant",
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => (
        <Text>{dateFormatter.toDateTime(info.getValue(), { timezone: NEW_YORK_TZ })}</Text>
      ),
      header: "Created At",
      meta: {
        csvFn: (x) => dateFormatter.toDateTime(x, { timezone: NEW_YORK_TZ }),
      },
      sortingFn: "instant",
    }),
  ];

  return columns;
}

const formatPatientStatus = (status: PatientStatus) => {
  switch (status) {
    case "ACCEPTED":
      return "Accepted";
    case "DECEASED":
      return "Deceased";
    case "ACTIVE":
      return "Active";
    case "DISCHARGED":
      return "Discharged";
    case "DRAFT":
      return "Draft";
    case "ELIGIBLE":
      return "Eligible";
    case "HOSPITALIZED":
      return "Hospitalized";
    case "LOST":
      return "Lost";
    case "ON_HOLD":
      return "On Hold";
    case "PENDING_FILES":
      return "Pending Files";
    case "REFERRAL":
      return "Referral";
    case "VACATION":
      return "Vacation";
    default:
      return "Unknown";
  }
};
