import { useQueryClient } from "@tanstack/react-query";
import useSocketEvent from "../../hooks/useSocketEvent";
import useApi from "../../hooks/useApi";
import { GET_VISIT_ASSIGNMENTS_QUERY } from "../../../modules/visit/pages/assignments/visit-assignment.graphql";

export function VisitAssignmentListeners() {
  const { queries } = useApi();
  const queryClient = useQueryClient();
  useSocketEvent({
    key: "VisitAssignmentAssigneeUpdated",
    onEvent: (payload) => {
      queryClient.invalidateQueries(queries.visit.assignments.get(payload.assignmentId));
      queryClient.invalidateQueries({ queryKey: [GET_VISIT_ASSIGNMENTS_QUERY] });
    },
  });
  return <></>;
}
