import Page from "../../../../../shared/components/Page";
import { Props } from "../IntakeDashboardTabView";
import useIntakeDashboard from "../hooks/useIntakeDashboard";

export function IntakeDashboardTitle(props: Props) {
  const { tableQuery } = useIntakeDashboard({
    filters: props.filters,
  });

  if (tableQuery.isPending) {
    return <Page.Title>Intake Patients (...)</Page.Title>;
  }

  if (tableQuery.isError) {
    return <Page.Title>Intake Patients (Error)</Page.Title>;
  }

  return <Page.Title>{`Intake Patients (${tableQuery.data.length})`}</Page.Title>;
}
