import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { noop } from "@chakra-ui/utils";
import { LocalDateTime } from "@js-joda/core";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQueries,
  useQueryClient,
} from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../core/api";
import SingleDateTimePicker from "../../../shared/components/DatePicker/SingleDateTimePicker";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";
import { PatientId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { getFullName } from "../../../shared/utils/get-full-name";
import { IntakePatientProfileStatusPicker } from "./IntakePatientProfileStatusAndTrackPicker";

interface Props {
  isOpen: boolean;
  patient: Messages["TelephonyCallPatientParticipantEntityInfo"] | null;
  onClose: () => void;
}

export default function ScheduleNextCallDateModal({ patient, isOpen, onClose }: Props) {
  const [nextCallDate, setNextCallDate] = React.useState<LocalDateTime | null>(null);
  const { api } = useApi();
  const toast = useToast();
  const { mutate: updateNextCallDate, isPending } = useMutation({
    mutationFn: async () => {
      if (patient === null || nextCallDate === null) return;

      await api.put("./patient_intake/:patientId/next_call_date", {
        path: { patientId: patient.id },
        body: {
          nextCallDate: nextCallDate,
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "Call scheduled",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error scheduling call date",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSettled: onClose,
  });

  const handleSubmit = () => {
    if (patient === null) return;

    if (nextCallDate === null) {
      toast({
        title: "Call date is required",
        description: `Please schedule the next call for ${getFullName(patient)}`,
        status: "error",
        position: "top-right",
      });
      return;
    }

    updateNextCallDate();
  };

  if (patient === null) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} size="3xl" onClose={noop}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody my="6">
          <Flex justify="space-between">
            <Box flex={1}>
              <Heading fontWeight="semibold" mb="6" size="md">
                Schedule Next Call
              </Heading>
              <Text fontSize="md" mb={6}>
                Please schedule the next follow-up call for <b>{getFullName(patient)}</b>.
              </Text>

              <Flex alignItems="center" gap={2}>
                <SingleDateTimePicker selected={nextCallDate} onChange={setNextCallDate} />
                <Button colorScheme="blue" isLoading={isPending} mr={3} onClick={handleSubmit}>
                  Submit
                </Button>
              </Flex>
            </Box>
            <Box position="relative" px="10">
              <Divider orientation="vertical" />
              <AbsoluteCenter bg="white" px="4">
                OR
              </AbsoluteCenter>
            </Box>
            <Box flex={1}>
              <Heading fontWeight="semibold" mb="6" size="md">
                Change Patient Status
              </Heading>
              <Box w="60">
                <IntakePatientStatusSelectWrapper
                  patientId={patient.id}
                  onSubmittedStatusChange={onClose}
                />
              </Box>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function IntakePatientStatusSelectWrapper(props: {
  patientId: PatientId;
  onSubmittedStatusChange: () => void;
}) {
  const { api, queries } = useApi();

  const [intakeProfileQuery, intakeStatusQuery] = useQueries({
    queries: [
      queries.intake.profile(props.patientId),
      queryOptions({
        queryKey: queryKeys.patientIntake.intakeStatus(),
        placeholderData: keepPreviousData,
        queryFn: () => api.get("./patient_intake/intake_status", {}),
      }),
    ],
  });

  if (intakeProfileQuery.isPending || intakeStatusQuery.isPending) {
    return (
      <Stack>
        <Skeleton h="4" rounded="md" w="24" />
        <Skeleton h="8" rounded="md" />
        <Skeleton h="8" rounded="md" />
        <Skeleton h="8" rounded="md" w="32" />
      </Stack>
    );
  }

  if (intakeProfileQuery.isError || intakeStatusQuery.isError) {
    return <Text>Error loading intake profile</Text>;
  }

  const patientProfile = intakeProfileQuery.data.patient;
  const intakeStatuses = intakeStatusQuery.data.intakeStatuses;

  return (
    <IntakePatientStatusSelect
      intakeStatuses={intakeStatuses}
      patientProfile={patientProfile}
      onSubmitted={props.onSubmittedStatusChange}
    />
  );
}

const IntakePatientStatusSelect: React.FC<{
  patientProfile: Messages["IntakePatientProfile"];
  intakeStatuses: Messages["IntakeStatus"][];
  onSubmitted: () => void;
}> = ({ intakeStatuses, patientProfile, onSubmitted }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { api, queries } = useApi();
  const [patientInfo, setPatientInfo] = React.useState(patientProfile);
  const [currentParentStatus, setParentStatus] = React.useState(
    intakeStatuses.find((x) => x.id === patientInfo.intakeStatus.parentId)
  );
  const { mutate: changeStatus, isPending } = useMutation({
    mutationKey: ["edit-patient-intake-profile"],
    mutationFn: () =>
      api.put("./patients/:patientId/intake", {
        body: {
          id: patientProfile.id,
          intakeStatus: patientInfo.intakeStatus,
        },
        path: { patientId: patientProfile.id },
      }),
    onSuccess: (data) => {
      toast({
        title: "Intake status updated",
        status: "success",
        position: "top-right",
      });

      queryClient.setQueryData(queries.intake.profile(patientProfile.id).queryKey, data);
      queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.intakeCallsOrder() });
      queryClient.invalidateQueries(queries.intake.checklist(patientProfile.id));
      queryClient.invalidateQueries(queries.intake.history(patientProfile.id));
    },
    onError: (error) => {
      toast({
        title: "Error updating intake status",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSettled: onSubmitted,
  });

  if (currentParentStatus === undefined) return null;

  return (
    <>
      <IntakePatientProfileStatusPicker
        currentParentStatus={currentParentStatus}
        currValue={patientInfo.intakeStatus}
        isEditable={true}
        isRequired={true}
        label="Intake Status"
        options={intakeStatuses}
        onChange={(value) => {
          if (value === null) return;
          setPatientInfo({ ...patientInfo, intakeStatus: value });
        }}
        onChangeParentStatus={setParentStatus}
      />
      <Button isLoading={isPending} mt="4" variant="outline" onClick={() => changeStatus()}>
        Change Status
      </Button>
    </>
  );
};
