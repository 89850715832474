import { BoxProps, Flex, Link, Tag, Text } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import { phoneFormatter } from "@medflyt/webapp-react/src/shared/utils/phone-formatter";
import React from "react";
import { Messages } from "../../core/api";
import { TableCellBadge } from "../../modules/communication/components/TableCellBadge";
import { CommCenterTicketStatus } from "../../modules/communication/utils/communication-utils";
import { useEntityLink } from "../hooks/useEntityLink";
import {
  AgencyMemberId,
  AgencyPhonebookContactId,
  CaregiverId,
  CommCenterTicketId,
  PatientId,
  VisitBroadcastId,
  VisitInstanceId,
} from "../schema/schema";
import { dateFormatter } from "../utils/date-formatter";
import { getEntityStatusColorScheme } from "../utils/get-entity-status-color-scheme";
import ImageCircleSkeleton from "./ImageCircleSkeleton";

export interface PatientContactDetails {
  firstName: string;
  lastName: string;
  relationship: string;
}

export type CaregiverEntity = {
  type: "Caregiver";
  id: CaregiverId;
  displayId: number | null;
  photoUrl: string | null;
  fullName: string;
  status: Messages["CaregiverStatus"];
};

export type PatientEntity = {
  type: "Patient";
  id: PatientId;
  displayId: number | null;
  gender: string | null;
  fullName: string;
  status: Messages["PatientStatus"];
  contactDetails: PatientContactDetails | null;
  selfServe?: boolean;
  wasSelfServe?: boolean;
};

export type IntakePatientEntity = Omit<PatientEntity, "type" | "status"> & {
  type: "IntakePatient";
  intakeStatus: string;
  selfServe: boolean;
  wasSelfServe: boolean;
};

export type AgencyMemberEntity = {
  type: "Agency Member";
  id: AgencyMemberId;
  fullName: string;
  photoUrl: string | null;
};

export type NotIdentifiedPhoneNumberEntity = {
  type: "NotIdentifiedPhoneNumber";
  phoneNumber: string;
};

export type FaxNotAssignedEntity = {
  type: "FaxNotAssignedEntity";
  status: "DRAFT";
};

export type PhonebookContactEntity = {
  type: "PhonebookContact";
  id: AgencyPhonebookContactId;
  name: string;
};

export type VisitInstanceEntity = {
  type: "VisitInstance";
  id: VisitInstanceId;
  startTime: LocalDateTime;
  endTime: LocalDateTime;
};

export type VisitBroadcastEntity = {
  type: "VisitBroadcast";
  id: VisitBroadcastId;
};

export type CommCenterTicketEntity = {
  type: "CommCenterTicket";
  id: CommCenterTicketId;
  status: CommCenterTicketStatus;
  label: Messages["CommCenterLabel"] | null;
};

export type NotIdentifiedEmailEntity = {
  type: "NotIdentifiedEmailEntity";
  email: string;
};

function getEntityTypeDisplayText(entity: Entity): string {
  switch (entity.type) {
    case "Caregiver":
    case "Patient":
    case "Agency Member":
      return entity.type;
    case "PhonebookContact":
      return "Phonebook Contact";
    case "IntakePatient":
      return "Intake Patient";
    case "NotIdentifiedPhoneNumber":
      return "Not Identified";
    case "FaxNotAssignedEntity":
      return "Not Assigned";
    case "VisitInstance":
      return "Visit";
    case "VisitBroadcast":
      return "Broadcast";
    case "CommCenterTicket":
      return "Ticket";
    case "NotIdentifiedEmailEntity":
      return "Not Identified";
  }
}

export type Entity =
  | CaregiverEntity
  | PatientEntity
  | IntakePatientEntity
  | NotIdentifiedPhoneNumberEntity
  | FaxNotAssignedEntity
  | AgencyMemberEntity
  | PhonebookContactEntity
  | VisitInstanceEntity
  | VisitBroadcastEntity
  | CommCenterTicketEntity
  | NotIdentifiedEmailEntity;

export type EntityWithStatus<$Entity extends Entity> = $Entity & {
  status: {
    Caregiver: Messages["CaregiverStatus"];
    Patient: Messages["PatientStatus"];
    IntakePatient: Messages["PatientStatus"];
    NotIdentifiedPhoneNumber: null;
    FaxNotAssignedEntity: "DRAFT";
    "Agency Member": "Agency Member";
    PhonebookContact: "Phonebook Contact";
    VisitInstance: null;
    CommCenterTicket: Messages["CommCenterTicketStatus"];
    VisitBroadcast: null;
    NotIdentifiedEmailEntity: null;
  }[$Entity["type"]];
};

interface Props {
  entity: Entity;
  boxProps?: BoxProps;
}

const EntityCard = (props: Props) => {
  const { entity, boxProps } = props;

  return (
    <Flex gap={3} {...boxProps}>
      <ImageCircleSkeleton size="35px" src={getEntityPhotoUrl(entity)} />
      <Flex direction="column" gap={1}>
        <Flex>
          {(() => {
            switch (entity.type) {
              case "Agency Member":
              case "Caregiver":
                return (
                  <Text>
                    {entity.fullName}{" "}
                    {`(${"displayId" in entity ? entity.displayId ?? entity.id : entity.id})`}
                  </Text>
                );
              case "Patient":
              case "IntakePatient":
                if (entity.contactDetails) {
                  return (
                    <Flex alignItems="center" direction="row" gap={2}>
                      <Text>
                        {entity.contactDetails.firstName} {entity.contactDetails.lastName}
                      </Text>
                      <Tag bg="transparent" color="gray.700" size="sm">
                        {entity.fullName} ({entity.displayId ?? entity.id})
                      </Tag>
                    </Flex>
                  );
                }

                return (
                  <Text>
                    {entity.fullName} ({entity.displayId ?? entity.id})
                  </Text>
                );
              case "NotIdentifiedPhoneNumber":
                return <Text>{phoneFormatter.formatNationalIfValid(entity.phoneNumber)}</Text>;
              case "FaxNotAssignedEntity":
                return <Text>Not Assigned</Text>;
              case "PhonebookContact":
                return <Text>{entity.name}</Text>;
              case "VisitInstance":
                return (
                  <Text>
                    {dateFormatter.toDateTimeRange(entity.startTime, entity.endTime, {
                      overnight: true,
                    })}
                  </Text>
                );
              case "VisitBroadcast":
                return <Text>Visit Broadcast</Text>;
              case "CommCenterTicket":
                return <Text>{entity.label?.name ?? "Chat"}</Text>;
              case "NotIdentifiedEmailEntity":
                return <Text>{entity.email}</Text>;
            }
          })()}
        </Flex>
        <Flex alignItems="center" gap={2}>
          {entity.type === "Caregiver" || entity.type === "Patient" ? (
            <TableCellBadge
              color={getEntityStatusColorScheme(entity.status)}
              text={entity.status}
            />
          ) : null}
          {entity.type === "IntakePatient" && (
            <TableCellBadge color="gray" text={entity.intakeStatus} />
          )}
          {(entity.type === "IntakePatient" || entity.type === "Patient") &&
            (entity.selfServe === true ? (
              <TableCellBadge color="blue" text="Self Serve" />
            ) : entity.wasSelfServe === true ? (
              <TableCellBadge color="blue" text="Was Self Serve" />
            ) : null)}
          {(entity.type === "Patient" || entity.type === "IntakePatient") &&
          entity.contactDetails ? (
            <TableCellBadge color="blue" text={entity.contactDetails.relationship.toUpperCase()} />
          ) : null}

          <Text color="gray.700" fontSize={10}>
            {getEntityTypeDisplayText(entity)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const EntityCardLink = (
  props: Props & {
    onClick?: () => void;
    onBeforeClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  }
) => {
  const { open } = useEntityLink();

  const handleClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (props.entity.type === "Caregiver" || props.entity.type === "Patient") {
      if (props.onBeforeClick !== undefined) {
        props.onBeforeClick(event);

        // Hack for when onBeforeClick is used to alter the URL and the 'open' function transitions
        // to the new URL before the URL is updated from the 'onBeforeClick' function.
        const entity = props.entity;
        setTimeout(() => {
          open({ event, entity });
        }, 500);
      } else {
        open({ event, entity: props.entity });
      }

      if (props.onClick !== undefined) {
        props.onClick();
      }
    }
  };

  const boxProps: BoxProps =
    props.entity.type !== "NotIdentifiedPhoneNumber"
      ? {
          ...props.boxProps,
          as: Link,
          onClick: handleClick,
          p: 3,
          borderRadius: "lg",
          _hover: { bg: "gray.100", color: "black", textDecoration: "none" },
        }
      : {};

  return <EntityCard {...props} boxProps={boxProps} />;
};

export const PatientPhotoUrl = {
  M: "/admin/images/patient-men.png",
  F: "/admin/images/patient-women.png",
};

export function getEntityPhotoUrl(
  entity:
    | Pick<CaregiverEntity, "photoUrl">
    | Pick<PatientEntity, "gender">
    | NotIdentifiedPhoneNumberEntity
    | FaxNotAssignedEntity
    | PhonebookContactEntity
    | VisitInstanceEntity
    | VisitBroadcastEntity
    | CommCenterTicketEntity
    | NotIdentifiedEmailEntity
) {
  switch (true) {
    case "photoUrl" in entity && entity.photoUrl !== null:
      return entity.photoUrl;
    case "gender" in entity && entity.gender === "F":
      return PatientPhotoUrl.F;
    case "gender" in entity && entity.gender === "M":
      return PatientPhotoUrl.M;
    case "type" in entity && entity.type === "VisitInstance":
      return "/admin/images/icons/mf-calendar.svg";
    case "type" in entity && entity.type === "CommCenterTicket":
      return "/admin/images/icons/chat.svg";
    case "type" in entity && entity.type === "VisitBroadcast":
      return "/admin/images/icons/broadcast.svg";
    default:
      return "/admin/images/blank-profile.jpg";
  }
}

export default EntityCard;
