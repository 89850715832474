import { CSSWithMultiValues, StyleProps, SystemStyleObject, TableRowProps } from "@chakra-ui/react";
import {
  AccessorKeyColumnDef,
  Column,
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
} from "@tanstack/react-table";
import { fmap } from "../../utils";
import { SortDir } from "../../schema/gql/graphql";

export const DATA_TABLE_PAGE_SIZES = [5, 10, 25, 50, 100, 250, 500];
export const DATA_TABLE_DEFAULT_PAGE_SIZE = DATA_TABLE_PAGE_SIZES[2];

export function getClickableRowProps<TData>(params: {
  row: Row<TData>;
  clickable: boolean;
  onClick?: (e: React.MouseEvent<HTMLTableRowElement>, row: Row<TData>) => void;
}): TableRowProps {
  if (!params.clickable) {
    return {};
  }

  return {
    cursor: "pointer",
    _hover: {
      bg: "blue.50",
    },
    onClick: (e: React.MouseEvent<HTMLTableRowElement>) => params.onClick?.(e, params.row),
  };
}

export function getStickyProps<TData, TValue>(
  column: Column<TData, TValue>
): (StyleProps & { _after?: CSSWithMultiValues }) | undefined {
  if (column.columnDef.meta?.sticky === undefined) {
    return undefined;
  }

  return {
    position: "sticky",
    [column.columnDef.meta.sticky]: 0,
    zIndex: 1,
    bg: "inherit",
    _after: fmap(column.columnDef.meta.sticky, psuedoShadow) ?? undefined,
  };
}

export function mapColumnFiltersState<TData>(
  columnFilters: ColumnFiltersState,
  columns: ColumnDef<TData, any>[]
): Record<string, unknown> {
  const mapped: Record<string, unknown> = {};

  for (const filter of columnFilters) {
    const column = getColumnByPrimary(filter.id, columns);

    if (column?.meta?.gqlFilterKey === undefined) {
      continue;
    }

    mapped[column.meta.gqlFilterKey] = (() => {
      const parsed = (() => {
        switch (column.meta.gqlFilterType) {
          case "number":
            return Number(filter.value);
          case undefined:
            return filter.value === "" ? null : filter.value;
        }
      })();

      switch (column.meta.gqlFilterMap) {
        case "eq":
          return { eq: parsed };
        case "in":
          return { in: [parsed] };
        case undefined:
          return parsed;
      }
    })();
  }

  return mapped;
}

export function mapSortingState<TData>(
  sorting: SortingState,
  columns: ColumnDef<TData, any>[]
): { field: string; sort: `${SortDir}` }[] {
  return sorting.map((x) => {
    const column = getColumnByPrimary(x.id, columns);

    const sort = (() => {
      switch (column?.sortUndefined) {
        case -1:
        case "first":
          return x.desc ? "desc" : "ascNullsFirst";
        case 1:
        case "last":
          return x.desc ? "descNullsLast" : "asc";
        case undefined:
        case false:
          return x.desc ? "desc" : "asc";
      }
    })();

    return {
      field: getColumnByPrimary(x.id, columns)?.meta?.gqlSortKey ?? x.id,
      sort: sort,
    };
  });
}

export function getColumnByPrimary<TData>(
  primaryKey: string,
  columns: ColumnDef<TData, any>[]
): ColumnDef<TData, any> | undefined {
  return columns.find((column) => getColumnPrimaryKey(column) === primaryKey);
}

function isAccessorKeyColumnDef<TData, TValue>(
  columnDef: ColumnDef<TData, TValue>
): columnDef is AccessorKeyColumnDef<TData, TValue> {
  return "accessorKey" in columnDef;
}

export function getColumnPrimaryKey<TData, TValue>(
  columnDef: ColumnDef<TData, TValue>
): string | undefined {
  if (columnDef.id !== undefined) {
    return columnDef.id;
  }

  if (isAccessorKeyColumnDef(columnDef)) {
    return String(columnDef.accessorKey).replaceAll(".", "_");
  }

  return undefined;
}

const psuedoShadow = (position: "left" | "right"): SystemStyleObject => ({
  content: '""',
  position: "absolute",
  top: 0,
  left: position === "right" ? -1 : undefined,
  right: position === "left" ? -1 : undefined,
  bottom: 0,
  width: 1,
  transition: "opacity 0.2s",
  backgroundImage:
    position === "right"
      ? "linear-gradient(to right, transparent, rgba(0, 0, 0, 0.05))"
      : "linear-gradient(to left, transparent, rgba(0, 0, 0, 0.05))",
});
