import { UseQueryResult } from "@tanstack/react-query";
import { EntitySelect } from "../../../../workflow/components/EntityFormControl";
import { AssignmentRecord } from "../assignments.utils";
import { useUpdateVisitAssignmentMutation } from "../hooks/useUpdateVisitAssignment";
import { VisitAssigmentEntityCardError } from "./VisitAssigmentEntityCardError";
import { VisitAssignmentEntityCardSkeleton } from "./VisitAssignmentEntityCardSkeleton";

export function VisitAssignmentAssignee(props: { query: UseQueryResult<AssignmentRecord> }) {
  const updateVisitAssignment = useUpdateVisitAssignmentMutation();

  switch (props.query.status) {
    case "pending":
      return <VisitAssignmentEntityCardSkeleton />;
    case "error":
      return <VisitAssigmentEntityCardError />;
    case "success": {
      const id = props.query.data.id;
      return (
        <EntitySelect
          hardFilters={{
            visitAssignmentAvailableOnly: true,
          }}
          input={{ type: "entity", entity: "Agency Member", nullable: true }}
          isLoading={updateVisitAssignment.isPending}
          isRequired={true}
          label="Assigne Agency Member"
          multiple={false}
          value={props.query.data.assignee?.agencyMember?.id ?? null}
          onChange={(assigneeId) => updateVisitAssignment.mutate({ id, body: { assigneeId } })}
        />
      );
    }
  }
}
