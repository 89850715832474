import { useDisclosure } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";

export default function useScheduleNextCall() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [patient, setPatient] = React.useState<
    Messages["TelephonyCallPatientParticipantEntityInfo"] | null
  >(null);

  const { queries } = useApi();

  const query = useQuery(queries.intake.nextCallDate(patient?.id ?? null));

  React.useEffect(() => {
    if (patient === undefined) {
      return;
    }

    if (
      query.isSuccess &&
      (query.data.nextCallDate === null || query.data.nextCallDate.isBefore(LocalDateTime.now()))
    ) {
      onOpen();
    }
  }, [query.data?.nextCallDate, query.isSuccess, patient, onOpen]);

  const close = () => {
    setPatient(null);
    onClose();
  };

  return {
    isOpen: isOpen,
    patient: patient,
    setPatient: setPatient,
    close: close,
  };
}
