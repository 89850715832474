import React from "react";
import { useIntakeCallsOrder } from "./useIntakeCallsOrder";
import { Instant, ZoneId } from "@js-joda/core";
import { NEW_YORK_TZ } from "../../../../../shared/utils/date-utils";
import { AgencyMemberId } from "../../../../../shared/schema/schema";
import { Messages } from "../../../../../core/api";
import assert from "../../../../../shared/utils/assert";

const INTAKE_QUEUE_NAMES = {
  Q1High: "Q1 - High",
  Q1Low: "Q1 - Low",
  Q1Initial: "Q1 - Initial",
  Q2Regular: "Q2 - Regular",
  Q3CallToPlan: "Q3 - Call To Plan",
} as const;

const QUEUES_ORDER = [
  INTAKE_QUEUE_NAMES.Q1High,
  INTAKE_QUEUE_NAMES.Q1Initial,
  INTAKE_QUEUE_NAMES.Q1Low,
  INTAKE_QUEUE_NAMES.Q2Regular,
  INTAKE_QUEUE_NAMES.Q3CallToPlan,
];

export function useIntakeAgencyMemberCallsOrder() {
  const callsOrder = useIntakeCallsOrder();
  const agencyMemberOrderedCalls = React.useMemo(() => {
    const now = Instant.now().atZone(ZoneId.of(NEW_YORK_TZ)).toLocalDate();
    const map = new Map<AgencyMemberId, Messages["IntakeCallOrder"][]>();
    for (const key of QUEUES_ORDER) {
      for (const call of callsOrder.data?.[key] ?? []) {
        if (call.assignedAgencyMemberId === null) {
          continue;
        }
        const agencyMemberOrders = map.get(call.assignedAgencyMemberId) ?? [];
        agencyMemberOrders.push(call);
        map.set(call.assignedAgencyMemberId, agencyMemberOrders);
      }
    }
    for (const [, calls] of map) {
      calls.sort((a, b) => {
        const aDate = a.callDate?.toLocalDate() ?? a.manualLocalDateTime?.toLocalDate();
        const bDate = b.callDate?.toLocalDate() ?? b.manualLocalDateTime?.toLocalDate();
        assert(aDate !== undefined && bDate !== undefined, "Call dates must be defined");
        if (aDate.equals(now) && bDate.equals(aDate)) {
          return 0;
        }
        if (aDate.equals(now)) {
          return -1;
        }
        if (bDate.equals(now)) {
          return 1;
        }
        return 0;
      });
    }
    return map;
  }, [callsOrder.data]);

  return agencyMemberOrderedCalls;
}
