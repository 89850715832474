import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BodyOf } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { queryKeys } from "../../../../../shared/query-keys";
import { useRouter } from "@uirouter/react";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";

export function useCreateNewIntakePatient() {
  const { api } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { stateService } = useRouter();

  const createIntakePatientDisclosure = useDisclosure();

  const { mutate: handleCreateNewIntakePatient, reset } = useMutation({
    mutationFn: (body: BodyOf<"post", "./patient_intake">) =>
      api.post("./patient_intake", { body }),
    onSuccess: (intakePatient) => {
      toast({
        title: "Intake Patient Created Successfully",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      createIntakePatientDisclosure.onClose();
      queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.intakeCallsOrder() });

      stateService.go("app.patients.intake.dashboard.profile", {
        patientId: intakePatient.patient.id,
      });
      reset();
    },
    onError: (error) => {
      toast({
        title: "Could not create new patient",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return {
    createIntakePatient: handleCreateNewIntakePatient,
    createIntakePatientDisclosure,
  };
}
