import { Badge } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../../../../shared/hooks/useApi";

export function IncompletePatientFlowBadge() {
  const { queries } = useApi();
  const query = useQuery(queries.intake.incompletePatients());

  if (query.isPending || query.isError) {
    return null;
  }

  return (
    <Badge
      alignItems="center"
      background="red"
      borderRadius="md"
      color="white"
      display="flex"
      h="22px"
      justifyContent="center"
      position="absolute"
      right="-10px"
      top="-5px"
      w="22px"
    >
      {query.data.patientsCount}
    </Badge>
  );
}
